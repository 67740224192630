import React, { useState } from "react";
import { FaqItem } from "..";
import "./Faq.css";

import { faqs } from '../../helpers/firmData';

export const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const renderTitle = () => {
    return <h1 className='title titleTeam' id='downTitleText'>NAJCZĘŚCIEJ ZADAWANE PYTANIA</h1>;
  }

  const renderFaqItem = () => {
    return faqs.map((faq, index) => (
      <FaqItem
        key={index}
        index={index}
        question={faq.question}
        answer={faq.answer}
        isOpen={openIndex === index}
        toggleFAQ={toggleFAQ}
      />
    ))
  }

  return (
    <>
      {renderTitle()}
      <div className="faq-container">
        <div className="faq-list">
          {renderFaqItem()}
        </div>
      </div>
    </>
  );
}
import {
  img1, img2, img3, img4, checklist, debitCard, grupa, plac, voucher1, voucher2, voucher3, voucher4, quality
} from '../assets/images/index.js';

export const tableOfPersons = [
  {id: 4, name: 'Mateusz', jpg: img3},
  {id: 1, name: 'Barbara', jpg: img2},
  {id: 2, name: 'Roman', jpg: img4},
  {id: 3, name: 'Sebastian', jpg: img1},
]

export const tableOfFeature = [
  {id: 1, title: '10-krotny mistrz', description: 'Zdawalności wśród szkół z Rudy Śląskiej.', jpg: quality },
  {id: 2, title: 'Grupy max. 12 osobowe', description: 'Od lat stawiamy jakość ponad ilość.', jpg: grupa},
  {id: 3, title: 'Toyota Yaris III', description: 'Pojazdy szkoleniowe takie jak na egzaminie państwowym.', jpg: checklist},
  {id: 4, title: 'Plac manewrowy', description: 'Zadaszony i oświetlony plac na Teranach Targowych Wirek.', jpg: plac},
  {id: 5, title: 'Płatność', description: 'Możliwość rozłożenia kosztów do pięciu nieoprocentowanych rat.', jpg: debitCard},
]

export const tableOfVoucher = [ voucher1, voucher2, voucher3, voucher4 ]

export const faqs = [
  {
    question: "Czy mogę rozpocząć kurs prawa jazdy przed ukończeniem 18 lat?",
    answer: "Tak, możesz rozpocząć kurs prawa jazdy kategorii B, jeśli masz co najmniej 17 lat i 9 miesięcy. Egzamin państwowy możesz zdawać 1 miesiąc przed ukończeniem 18 roku życia."
  },
  {
    question: "Jak długo trwa kurs prawa jazdy kategorii B?",
    answer: "Kurs podstawowy obejmuje 30 godzin lekcyjnych zajęć teoretycznych i 30 godzin zegarowych praktycznej nauki jazdy. Zajęcia teoretyczne odbywają się w sobotę oraz niedzielę (od 8:00 do 14:00) i trwają 2 weekendy. Czas trwania zajęć praktycznych (jazd) zależy od dyspozycyjności kursanta - średnio 6 tygodni."
  },
  {
    question: "Jakie dokumenty są potrzebne do rozpoczęcia kursu?",
    answer: "Do rozpoczęcia kursu potrzebujesz Profilu Kandydata na Kierowcę (PKK) wydany przez Wydział Komunikacji w Urzędzie Miasta, dowodu osobistego lub innego dokumentu tożsamości."
  },
  {
    question: "Czy pomagacie w uzyskaniu Profilu Kandydata na Kierowcę (PKK)?",
    answer: "Tak, udzielamy pełnej pomocy w procesie uzyskania PKK, aby ułatwić Ci rozpoczęcie kursu. Do uzyskania PKK potrzebujesz: wniosek o wydanie prawa jazdy, badanie lekarskie oraz zdjęcie do dokumentu prawa jazdy."
  },
  {
    question: "Czy muszę uczestniczyć w spotkaniu organizacyjnym przed rozpoczęciem kursu?",
    answer: "Obecność na spotkaniu organizacyjnym jest zalecana, ponieważ pomagamy tam wypełnić wniosek o wydanie prawa jazdy, a nasz zaprzyjaźniony lekarz wykonuje na miejscu badania niezbędne do uzyskania PKK. Jeśli nie możesz uczestniczyć w spotkaniu, pomożemy Ci załatwić wszystkie formalności indywidualnie."
  },
  {
    question: "Czy teoria odbywa się stacjonarnie czy online?",
    answer: "Zajęcia teoretyczne są obowiązkowe i odbywają się stacjonarnie w Rudzie Śląskiej na ul. Hallera 6 w sali nr 1 (Zespół Szkół nr 1)."
  },
  {
    question: "Jak wygląda proces rezerwacji jazd praktycznych?",
    answer: "Po zaliczonych wykładach umawiamy się na jazdy. Z każdym kursantem terminy ustalamy indywidualnie i staramy się być w tym jak najbardziej elastyczni."
  },
  {
    question: "Co się stanie, jeśli nie stawię się na umówione jazdy?",
    answer: "Jeśli nie możesz pojawić się na umówioną jazdę, wystarczy że poinformujesz nas z wyprzedzeniem i ustalimy dogodny termin. Brak wcześniejszego zgłoszenia może skutkować naliczeniem opłaty za godziny pracy instruktora."
  },
  {
    question: "Czy mogę wykupić dodatkowe godziny jazd przed egzaminem?",
    answer: "Tak, oferujemy możliwość wykupienia dodatkowych godzin jazd doszkalających, aby lepiej przygotować się do egzaminu. Jeśli ukończyłeś kurs prawa jazdy w innej szkole, to nadal możesz wykupić jazdy dodatkowe w OSK BOLID."
  },
  {
    question: "Czy mogę wybrać instruktora, z którym chcę mieć jazdy?",
    answer: "Tak, możesz wskazać preferowanego instruktora, a my postaramy się dostosować grafik jazd do Twoich oczekiwań."
  }
];

import React, { useEffect, useState } from "react";
import './Comments.css';

export const Comments = ({ placeId }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const response = await fetch(`https://oskbolid.pl/google.php?place_id=${placeId}`);
      const data = await response.json();
      console.log(data)
      if (data.result && data.result.reviews) {
        setReviews(data.result.reviews);
      }
    };

    console.log('leci')

    fetchReviews();
  }, [placeId]);

  return (
    <div className="reviews-container">
      {reviews.length === 0 ? (<div></div>) : (
        <div className="reviews-grid">
          {reviews.map((review, index) => (
            <div className="review-card" key={index}>
              <div className="review-header">
                <div>
                  <p className="review-time">{review.author_name}</p>
                  <p className="review-time">{review.relative_time_description}</p>
                </div>
              </div>
              <p className="review-rating">⭐ {review.rating}/5</p>
              <p className="review-text">{review.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { faq_phone, faq_mobile, faq_crosses } from "../../assets/images";

import './BolidAcademy.css'

export const BolidAcademy = () => {
  const renderTitle = () => {
    return <h1 className='title titleTeam' id='downTitleText'>AKADEMIA JAZDY BOLID</h1>;
  }

  return (
    <>
      {renderTitle()}
      <div className="flex flex-col items-center gap-4">
        <img src={faq_phone} alt="Main" className="w-3/4 h-auto rounded-xl shadow-lg photo"/>
        <div className="flex gap-4 w-3/4 photo-container">
          <div className="item--container">
            <img src={faq_mobile} alt="Secondary 1" className="photo--small"/>
            <h3>Aplikacja dostosowana do urządzeń mobilnych</h3>
          </div>
          <div className="item--container">
            <img src={faq_crosses} alt="Secondary 1" className="photo--small"/>
            <h3 className="margin-text">Trasy, Manewry, Skrzyżowania</h3>
          </div>
        </div>
      </div>
    </>
  );
}

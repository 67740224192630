import React from 'react'
import { facebook, mail, instagram, phone, logo } from '../../assets/images';
import './Footer.css';
import { clickFacebook, clickInstagram, clickPhoneCall } from "../../helpers/metaPixel";

export const Footer = () => {

  return (
    <>
      <div>
        <img className='footerLogo'src={logo} alt="logo"/>
      </div>
      <div className='footerHelper'>
        <footer className="footer">
          <div className='elementFooter'>
            <p className='titleFooter'>Adres:</p>
            <p>Ośrodek Szkolenia Kierowców BOLID</p>
            <p>Hallera 6, 41-709 Ruda Śląska</p>
          </div>
          <div className='elementFooter'>
            <p className='titleFooter'>Adres mailowy:</p>
            <p>oskbolidrsl@gmail.com</p>
          </div>
          <div className='elementFooter'>
            <p className='titleFooter'>Telefon:</p>
            <p>696 447 501</p>
          </div>
          <div className='iconsFooter'>
            <a href="https://www.facebook.com/oskbolidrsl" target="_blank" rel="noreferrer" onClick={clickFacebook}><img className='iconFooter' src={facebook} alt="facebook"/></a>
            <a href = "mailto: oskbolidrsl@gmail.com" target="_blank" rel="noreferrer"><img className='iconFooter' src={mail} alt="email"/></a>
            <a href="https://instagram.com/oskbolid?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer" onClick={clickInstagram}><img className='iconFooter' src={instagram} alt="instagram"/></a>
            <a href="tel:696-447-501" onClick={clickPhoneCall}><img className='iconFooter' src={phone} alt="phone"/></a>
          </div>
        </footer>
      </div>
    </>
  )
}

import React from 'react';
import { ChevronDown, ChevronUp } from "lucide-react";
import "./FaqItem.css";

export const FaqItem = ({ index, question, answer, isOpen, toggleFAQ }) => {
  const questionIcon = () => {
    return isOpen ? <ChevronUp size={15} /> : <ChevronDown size={15} />
  }

  const answerText = () => {
    return isOpen && <p className="faq-answer">{answer}</p>
  }

  return (
    <div className="faq-item">
      <button className="faq-question" onClick={() => toggleFAQ(index)}>
        {question} {questionIcon()}
      </button>
      {answerText()}
    </div>
  );
}
export const signInByMenu = () => {
  window.fbq('track', 'signInByMenu', { name: 'click from menu' });
}

export const signInByLandingPage = () => {
    window.fbq('track', 'signInByLandingPage', { name: 'click from landing page' });
}

export const signInByOffer = () => {
    window.fbq('track', 'signInByOffer', { name: 'click from offer' });
}

export const signInButton = () => {
    window.fbq('track', 'signInButton', { name: 'click sign in button' });
}

export const clickBonDialog = () => {
  window.fbq('track', 'clickBonDialog', { name: 'click bon dialog window' });
}

export const clickFacebook = () => {
  window.fbq('track', 'clickFacebookButton', { name: 'click facebook' });
}

export const clickInstagram = () => {
    window.fbq('track', 'clickInstagramButton', { name: 'click instagram' });
}

export const clickPhoneCall = () => {
  window.fbq('track', 'clickPhoneCallButton', { name: 'click phone' });
}